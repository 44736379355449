<template>
  <div class="job-card-list">
    <div
      class="card-item cursor"
      v-for="(item, index) in cardList"
      :key="index"
      @click="handleClick(item)"
    >
      <div class="item-top">
        <div class="item-top-left">
          <div class="job-name">{{ item.Name }}</div>
          <div class="job-info">
            {{ item.EducationDescription }} | {{ item.ExperienceDescription }} |
            {{ item.RecruitTypeDescription }}
          </div>
        </div>
        <div class="item-top-right">
          {{ item.SalaryMin }}K-{{ item.SalaryMax }}K/月
        </div>
      </div>
      <div class="item-bottom">
        <div class="item-bottom-left">
          <img
            :src="
              item.EnterpriseLogo
                ? item.EnterpriseLogo
                : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimgs.ycfcw.cn%2Fnewimg%2Fnews%2F2017-12%2F16%2F1136155167.png%40%21200-150&refer=http%3A%2F%2Fimgs.ycfcw.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1636256812&t=1279b77ccd14607a6d91bd12eeba2b76'
            "
            alt=""
          />
          <div class="enterprise-name">{{ item.EnterpriseName }}</div>
        </div>
        <div class="item-bottom-right">{{ item.WorkPlace }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobCard",
  props: {
    cardList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick(item) {
      console.log("item: ", item);
      this.$router.push(`/job/detail?id=` + item.Id);
      // let routeUrl = this.$router.resolve({
      //   path: "/job/detail",
      //   query: { id: item.Id }
      // });
      // window.open(routeUrl.href, "/path");
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.job-card-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
  // justify-content: space-between;
  .card-item {
    width: 355px;
    height: 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.12);
    border-radius: 4px;
    margin-bottom: 20px;
    margin-right: 16px;
    padding: 16px;
    box-sizing: border-box;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .item-top {
      height: 63px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      .item-top-left {
        flex-grow: 1;
        max-width: 240px;
        .job-name {
          white-space: nowrap; /*一行显示*/
          overflow: hidden; /*超出部分隐藏*/
          text-overflow: ellipsis; /*用...代替超出部分*/
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333;
          line-height: 22px;
        }
        .job-info {
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          margin-top: 8px;
        }
      }
      .item-top-right {
        width: 82px;
        font-size: 14px;
        font-weight: 600;
        color: #ff7946;
        line-height: 20px;
        text-align: right;
      }
    }
    &:hover {
      box-shadow: 0px 0px 28px 0px rgba(51, 51, 51, 0.16);
      position: relative;
      top: -4px;
      .job-name {
        color: #3d5afe !important;
      }
    }
    .item-bottom {
      height: 30px;
      padding-top: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item-bottom-left {
        flex-grow: 1;
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 8px;
        }
        .enterprise-name {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
      }
      .item-bottom-right {
        width: 102px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        text-align: right;
      }
    }

    h3 {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin: 8px 0;
      padding: 0 16px;
      box-sizing: border-box;
      width: 100%;
      text-align: center;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .tags {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
}
</style>
